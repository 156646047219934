.card {
  background: var(--surface-card);
  padding: 1.5rem;
  margin-bottom: 1rem;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 24px 36px rgba(0, 0, 0, 0.04);
  border-radius: 14px;
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 7rem;
  }
}
