@import "./assets/layout/styles/layout/layout.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";

.invalid {
  border-color: rgb(193, 25, 25) !important;
}

.custom-chip-primary {
  .p-chip {
    margin: 1vh;
    background: #4459a4; /* Color de fondo primario */
    color: white;
  }
}

.cardDashboard {
  .p-card {
    text-align: center;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  ion-spinner {
    height: 100px;
    width: 100px;
  }
}

.spinner-container-indicator {
  display: grid;
  height: 100%;
  width: 100%;
  ion-spinner {
    height: 100px;
    width: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  ion-label {
    text-align: center;
  }

  .p-datatable {
    th,
    tr,
    td {
      text-align: center;
    }
  }

  p-password {
    .p-password {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }

  .calendar-form {
    .p-calendar {
      width: 100%;
    }
  }

  .p-dropdown {
    width: 100%;
  }
}
